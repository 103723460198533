
import { Badge, Button, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

import { Redirect, Link } from 'react-router-dom';

import '../../../scss/dashboardStyles.css';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configMessages from '../../../config/configMessages';
import apiCall from '../../../config/apiCalls';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import { connect } from 'react-redux'

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStop, faPlay,faCopy } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import stringSimilarity from "string-similarity";
library.add(faCog, faPrint, faGripHorizontal, faList);
const ElasticIndexes = require("../../../config/elasticIndexes");

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

class DataTables extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      allUsersData: [],
      deleteRowDataId: {},
      tableFields: this.props.getTableFields(),
      originalTableFields: this.props.getTableFields(),
      tablefieldsToShow: this.props.getTableFields(),
      tableFields1: this.props.getTableFields1(),
      originalTableFields1: this.props.getTableFields1(),
      tablefieldsToShow1: this.props.getTableFields1(),
      isOpenShowHideColumnsModal: false,
      first: 0,
      rows: 100,
      sortified: false,
      direction: 'asc',
      filterCriteria: { limit: 100, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
      selectedRows: '',
      selectedRowsId: '',
      totalRecords: [],
      exportData: [],
      isOpenFormModal: false,
      openBulkUploadModal: false,
      openViewModal: false,
      isLoading: false,
      isOpenRolesModal: false,
      isOpenConfirmationModal: false,
      selectedScreenPermission: '',
      loginRole: 'Admin',
      startDate: '',
      endDate: '',
      apiUrl: this.props.apiUrl,
      totalRecordsLength: 0,
      tabType: this.props.tabType,

      sessionExpiryModal: false,
      mobileListFields: [],
      dateFormat: '',
      openTemplateModal: false,
      isOpenProductsModal: false,
      selectschool: '',
      roleType: '',
      openId: 1,
      redirecToLogin: false,
      openDeleteModal: false,
      blockFamily: false,
      selectActions: '',
      viewType: 'list',
      actions: '',
      displayViewOfForm: this.props.displayViewOfForm,
      displayBreadCrumbField: '',
      displayBreadCrumbValue: '',
      width: window.innerWidth,
      height: window.innerHeight,
      globalSearch: '',
      showMultiSelectDropDown: false,
      multiSelectTypes: '',
      actionsTypes: '',
      userStatus: '',
      selectedAutoCompleteValue: '',
      suggestions: [{ "label": `No Results` }],
      confirmModalText: '',
      confirmType: '',
      openUserPasswordResetModal: false,
      isPreviewModal: false,
      emailTemplateData: '',
      condition: 'OR',
      database: 'OPP',
      originalResponseData: [],
      elasticIndexOptions:[{ label: "properties-index-test", value: "properties-index-test" }, { label: "properties-index-prod", value: "properties-index-prod" }],
      scoringTypeOptions: [{ label: "Regular Scoring", value: "Regular Scoring" }, { label: "Deal Sizing", value: "Deal Sizing" }],
      regularLayerOptions:[
        { label: "Layer1", value: "loaderLayer1"
        },
        { label: "Layer2", value: "loaderLayer2"
        },
        { label: "Layer3", value: "loaderLayer3"
        },
        { label: "Layer4", value: "loaderLayer4"
        }
    ],
      dealSizingLayerOptions : [{ label: "Deal Sizing", value: "loaderDealSizing" }],
      scoringType: "Regular Scoring",
      elasticIndex: "properties-index-test", 
      states: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      EstateCount: 0,
      emailValidate: "no",
      greenNameOne: 0,
      greenNameTwo: 0,
      greenAddOne: 0,
      greenAddTwo: 0,
      greenCSZ_One: 0,
      greenCSZ_Two: 0,
      blueNameOne_1: 0,
      blueNameOne_2: 0,
      blueAddOne_1: 0,
      blueAddOne_2: 0,
      blueCSZ_One_1: 0,
      blueCSZ_One_2: 0,
      yellowNameOne_1: 0,
      yellowNameOne_2: 0,
      yellowNameTwo_1: 0,
      yellowNameTwo_2: 0,
      yellowAddOne: 0,
      yellowAddTwo: 0,
      yellowCSZ_One: 0,
      yellowCSZ_Two: 0,
      brownNameOne: 0,
      brownNameOne_2: 0,
      brownAddOne: 0,
      brownCSZ_One: 0,
      ElasticIndex: "both"
    };
  }

  componentDidMount = async () => {
    if (!document.getElementsByClassName("layout layout--collapse")[0]) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.type == "Buckets") {
      this.updateBucketsData();
    }
    if (this.props.type == "Setting") {
      this.updateSettingsData();
    }

    // this.setAutocompleteSuggestions()
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('mousedown', this.handleClickOutside)
    if (!loginCredentials) {
      await this.setState({ redirecToLogin: true })
    } else {
      await this.setState({
        redirecToLogin: false,
        displayViewOfForm: this.props.displayViewOfForm,
      })
    }
    let sessionexpired = localStorage.getItem('sessionexpired')
    if (sessionexpired == "true") {
      await this.setState({ sessionExpiryModal: true })
    }
    await this.getTimeFormat()
    await this.getLoginRole();
    await this.getActions();
    console.log(this.props.getTableFields1)
    if (this.props.getTableFields) {

      this.setState({
        tablefields: this.props.getTableFields(),
        originalTableFields: this.props.getTableFields(),
        isLoading: false,
        mobileListFields: this.props.getTableFields(),
      }, async () => {
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = await JSON.parse(colOrder);
        if (columns) {
          await this.getTableFieldsOrder();
        } else {
          await this.updateColumnsOrder(null);
        }
      });
    }
    this.getDataFromServer(this.state.filterCriteria);
    this.setState({
      formFields: this.props.formFields
    })
  }
  updateBucketsData = async () => {
    let request;
    let ThresholdValues = {
      greenNameOne: this.state.greenNameOne,
      greenNameTwo: this.state.greenNameTwo,
      greenAddOne: this.state.greenAddOne,
      greenAddTwo: this.state.greenAddTwo,
      greenCSZ_One: this.state.greenCSZ_One,
      greenCSZ_Two: this.state.greenCSZ_Two,
      blueNameOne_1: this.state.blueNameOne_1,
      blueNameOne_2: this.state.blueNameOne_2,
      blueAddOne_1: this.state.blueAddOne_1,
      blueAddOne_2: this.state.blueAddOne_2,
      blueCSZ_One_1: this.state.blueCSZ_One_1,
      blueCSZ_One_2: this.state.blueCSZ_One_2,
      yellowNameOne_1: this.state.yellowNameOne_1,
      yellowNameOne_2: this.state.yellowNameOne_2,
      yellowNameTwo_1: this.state.yellowNameTwo_1,
      yellowNameTwo_2: this.state.yellowNameTwo_2,
      yellowAddOne: this.state.yellowAddOne,
      yellowAddTwo: this.state.yellowAddTwo,
      yellowCSZ_One: this.state.yellowCSZ_One,
      yellowCSZ_Two: this.state.yellowCSZ_Two,
      brownNameOne: this.state.brownNameOne,
      brownNameOne_2: this.state.brownNameOne_2,
      brownAddOne: this.state.brownAddOne,
      brownCSZ_One: this.state.brownCSZ_One,
    }
    request = fetch(`${config.elasticApiUrl}/api/bucket/bucketGet?flag=server&ScoringType=${this.props.ScoringType}`, { method: "GET" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            // showToasterMessage(response.respMessage, 'success');
            this.setState({
              greenNameOne: response.details[0].greenNameOne,
              greenNameTwo: response.details[0].greenNameTwo,
              greenAddOne: response.details[0].greenAddOne,
              greenAddTwo: response.details[0].greenAddTwo,
              greenCSZ_One: response.details[0].greenCSZ_One,
              greenCSZ_Two: response.details[0].greenCSZ_Two,
              blueNameOne_1: response.details[0].blueNameOne_1,
              blueNameOne_2: response.details[0].blueNameOne_2,
              blueAddOne_1: response.details[0].blueAddOne_1,
              blueAddOne_2: response.details[0].blueAddOne_2,
              blueCSZ_One_1: response.details[0].blueCSZ_One_1,
              blueCSZ_One_2: response.details[0].blueCSZ_One_2,
              yellowNameOne_1: response.details[0].yellowNameOne_1,
              yellowNameOne_2: response.details[0].yellowNameOne_2,
              yellowNameTwo_1: response.details[0].yellowNameTwo_1,
              yellowNameTwo_2: response.details[0].yellowNameTwo_2,
              yellowAddOne: response.details[0].yellowAddOne,
              yellowAddTwo: response.details[0].yellowAddTwo,
              yellowCSZ_One: response.details[0].yellowCSZ_One,
              yellowCSZ_Two: response.details[0].yellowCSZ_Two,
              brownNameOne: response.details[0].brownNameOne,
              brownNameOne_2: response.details[0].brownNameOne_2,
              brownAddOne: response.details[0].brownAddOne,
              brownCSZ_One: response.details[0].brownCSZ_One,
              tealThreshold: response.details[0].tealThreshold,
              ScoringType: response.details[0].type,
            })
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
        }
      })

  }

  updateSettingsData = async () => {
    let request;
    request = fetch(`${config.elasticApiUrl}/api/settings`, { method: "GET" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            showToasterMessage(response.respMessage, 'success');
            this.setState({
              runPropertyIdsFlag: response.settings[0].runPropertyIds,
            })
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
        }
      })
  }

  getBucketDataFromServer = async () => {
    this.setState({
      BucketFromScore: '',
      isLoadingsearch: true
    });
    let nameScore = Number(this.state.percent) ? Number(this.state.percent / 100) : 0
    let addressLineScore = Number(this.state.addLinePercent) ? Number(this.state.addLinePercent / 100) : 0
    let CityStateZipScore = Number(this.state.addPercent) ? Number(this.state.addPercent / 100) : 0

    await this.updateBucketsData()
    var bucket = "";
    // console.log("nameScorenameScorenameScorenameScorenameScorenameScore")
    if ((nameScore * 100 >= this.state.greenNameOne && (addressLineScore * 100 >= this.state.greenAddOne && CityStateZipScore * 100 >= this.state.greenCSZ_One))
      || (nameScore * 100 >= this.state.greenNameTwo && (addressLineScore * 100 >= this.state.greenAddTwo || CityStateZipScore * 100 >= this.state.greenCSZ_Two))) {
      bucket = "Green";
    }
    else if ((nameScore * 100 >= this.state.blueNameOne_1 && nameScore * 100 <= this.state.blueNameOne_2) && ((addressLineScore * 100 >= this.state.blueAddOne_1 && addressLineScore * 100 <= this.state.blueAddOne_2)
      || (CityStateZipScore * 100 >= this.state.blueCSZ_One_1 && CityStateZipScore * 100 <= this.state.blueCSZ_One_2))) {
      bucket = "Blue";
    }
    else if (((nameScore * 100 >= this.state.yellowNameOne_1 && nameScore * 100 <= this.state.yellowNameOne_2) && (addressLineScore * 100 >= this.state.yellowAddOne && CityStateZipScore * 100 >= this.state.yellowCSZ_One))
      || ((nameScore * 100 >= this.state.yellowNameTwo_1 && nameScore * 100 <= this.state.yellowNameTwo_2) && (addressLineScore * 100 >= this.state.yellowAddTwo || CityStateZipScore * 100 >= this.state.yellowCSZ_Two))) {
      bucket = "Yellow";
    }
    else if ((nameScore * 100 >= this.state.brownNameOne && nameScore * 100 <= this.state.brownNameOne_2) && (addressLineScore * 100 >= this.state.brownAddOne && CityStateZipScore * 100 >= this.state.brownCSZ_One)) {
      bucket = "Brown";
    }
    else {
      bucket = "Black";
    }
    this.setState({
      BucketFromScore: bucket,
      isLoadingsearch: false
    })
  }
  CompareTwoStrings = async () => {
    this.setState({
      ScoreResult: '',
      reason: '',
      isLoadingsearch: true
    });
    let CompanyNameA = this.state.CompanyNameA
    let CompanyNameB = this.state.CompanyNameB

    // // remove special characters and spaces from company names
    // CompanyNameA = await this.RemoveSpecialCharacters(CompanyNameA)
    // CompanyNameB = await this.RemoveSpecialCharacters(CompanyNameB)

    // CompanyNameA = CompanyNameA.toLowerCase();
    // CompanyNameB = CompanyNameB.toLowerCase();
    // var similarity = stringSimilarity.compareTwoStrings(CompanyNameA, CompanyNameB);
    // this.setState({
    //   ScoreResult: similarity * 100,
    //   isLoadingsearch: false
    // })
    let request
    request = fetch(`${config.elasticApiUrl}/api/estate/compareStrings?str1=${CompanyNameA}&str2=${CompanyNameB}`, { method: "GET" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            // showToasterMessage(response.respMessage, 'success');
            this.setState({
              ScoreResult: response.result.score,
              reason: response.result.reason,
              isLoadingsearch: false
            })
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
        }
      })
  }

  RemoveSpecialCharacters = async (str) => {
    // Removing special characters from name score value and remove space between them.
    // Eg: Suntricity Power & Corporation ----> SuntricityPowerCorporation

    if (!str) {
      return "";
    }
    else {
      var sb = "";
      for (let c of str) {
        // this allows only particular characters from below condition
        if ((c >= '0' && c <= '9') || (c >= 'A' && c <= 'Z') || (c >= 'a' && c <= 'z')) {
          sb += c;
        }
      }
      return sb;
    }

  }

  getElasticQueryDataFromServer = async () => {
    this.setState({
      isLoadingsearch: true
    });
    // if (this.state.ElasticIndex == undefined) {
    //   showToasterMessage("Please select an Elastic index.", 'info');
    // } else {
    let request = fetch(`${config.elasticApiUrl}/api/estate/elasticQueryResults?elasticQuery=${JSON.stringify(this.state.elasticQuery)}&&typeOfIndex=${this.state.ElasticIndex}`, { method: "GET" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {

            let exportData = []
            response.details.forEach((item) => {
              exportData.push(item._source);
            });
            this.setState({
              exportData: exportData,
              isLoadingsearch: false
            }, () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();
            });

            // display message
            showToasterMessage(response.respMessage, 'success');
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
            this.setState({
              isLoadingsearch: false
            })
          }
        }
      })
    // }
  }

  setAutocompleteSuggestions = async (data) => {
    await fetchMethodRequest('GET', `api/estate/estateNames?query=${data}`)
      // return fetch(`${config.elasticApiUrl}/estateNames`, { method: 'GET' })
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (response && response.details && response.details.length > 0) {
            response.details.forEach((doc) => {
              dropdownData.push({ "label": doc })
            })
            this.setState({
              suggestions: dropdownData,
              suggestionsBack: dropdownData
            })
          } else {
            this.setState({
              suggestions: [{ "label": `No Results` }],
              suggestionsBack: [{ "label": `No Results` }]
            })

          }
        }
      }).catch((err) => {
        return err;
      });
  }
  getActions = async () => {
    let selectedRows = this.state.selectedRows ? [... this.state.selectedRows] : [];
    let actionsTypes = this.props.actionsTypes ? [... this.props.actionsTypes] : [];
    console.log(actionsTypes)
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selectedRows && selectedRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    await this.setState({
      actionsTypes: result
    })
  }

  handleClickOutside = (event) => {
    if (
      this.closeMultiSelectDropdown.current &&
      !this.closeMultiSelectDropdown.current.contains(event.target)
    ) {
      this.setState({
        showMultiSelectDropDown: false
      });
    }
  };
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    window.removeEventListener('resize', this.updateDimensions);
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  updateDimensions = async () => {
    await this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  getTimeFormat = () => {
    // let store=store.getState()
    // let dateFormat=store && store.commonData && store.commonData.data && store.commonData.data.timeFormat?store.commonData.data.timeFormat:'DD-MM-YYYY'
    // console.log('timeFormat',dateFormat)
  }

  // Handle Table fields to show in datatable
  getTableFieldsOrder() {
    this.setState({
      tablefieldsToShow: [],
      isLoading: true,
    })

    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...this.state.originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = this.state.originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        // TableFieldsToShow.push(item)
        if (item.show && this.state.viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && this.state.viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    this.setState({
      tablefieldsToShow: TableFieldsToShow,
      isLoading: false,
    })
  }
  changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = this.state.tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow,
        filterCriteria: filterCriteria
      })
      await this.onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow
      })
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await this.onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await this.onMultiSelectFilterChange(key, value, 'push', index)
      }

    }
  }
  changeViewType = async (viewType) => {
    await this.setState({ viewType: viewType })
    await this.getTableFieldsOrder()
  }
  // Handle Table fields order of display in DataTable
  updateColumnsOrder(currentOrder) {
    this.setState({
      isLoading: true,
    })
    let originalTableFields = [...this.state.originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...this.state.originalTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          originalTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false
            }
          )
        })
      }
    } else {
      originalTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile
          }
        )
      });
    }

    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
    this.getTableFieldsOrder(this.state.originalTableFields);
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    //console.log('store Data', storeData)
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    //console.log('timeFormat', dateFormat)
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };

    }
    return { dateFormat: dateFormat };

  }

  getLoginRole() {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        this.setState({
          loginRole: loginData.role
        })
      }
    }
  }

  updateSlNoToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (this.props.flags) {
        let flags = this.props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      } else {
        if (this.state.first) {
          item.Sno = (index + 1) + this.state.first;
        } else {
          item.Sno = index + 1;
        }

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < this.state.tablefieldsToShow.length; k++) {
        if ("Date" == this.state.tablefieldsToShow[k]['fieldType']) {
          itemdata[i][this.state.tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][this.state.tablefieldsToShow[k]['field']],
              this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  getDataFromServer = async (filterCriteria, type) => {
    let url = this.getAPIUrl();
    //let url = this.props.apiUrl;
    let dateFormat = this.state.dateFormat
    if (url) {
      let apiUrl;
      this.setState({
        isLoading: true,
        selectedRows: '',
      });
      if (type == 'refresh') {
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let tablefieldsToShow = this.state.tablefieldsToShow;
        if (tablefieldsToShow && tablefieldsToShow.length > 0) {
          for (let i = 0; i < tablefieldsToShow.length; i++) {
            let options = tablefieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        await this.setState({
          globalSearch: '',
          allUsersData: [],
          status: '',
          selectschool: '',
          roleType: '',
          tablefieldsToShow: tablefieldsToShow
        });

      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        let filterCriteria = {};
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
      }
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        delete filterCriteria['sortfield'];
        delete filterCriteria['direction'];
      }
      if (type === 'refresh' && filterCriteria) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (this.props.params) {
        console.log(this.props.params)
        let params = this.props.params
        for (let i in params) {
          console.log(i)
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)
            console.log(obj)
          }
        }
      }
      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (this.props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
      }
      else if (this.props.hasQueryInCall) {
        console.log('hi')
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        // apiUrl = this.getFilterUrl(filterCriteria, type);
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      console.log("apiUrl", apiUrl)
      if (url !== 'settings') {
        fetchMethodRequest('GET', apiUrl)
          .then(async (response) => {
            let apiResponseKey = this.props.apiResponseKey;
            let sessionexpired = localStorage.getItem('sessionexpired')
            if (sessionexpired == "true") {
              await this.setState({ sessionExpiryModal: true })
            }
            let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
            if (apiResponseKey === 'scorings') {
              response.details = await JSON.parse(response.details)
            }
            console.log("response.details", response.details)
            if (response && response.details && response.details.length > 0) {
              responseData = response.details;
              totalRecordsLength = response.details.length;
            } else {
              if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
                if (response.pagination && response.pagination.totalCount) {
                  totalRecordsLength = response.pagination.totalCount;
                }
                responseData = this.updateSlNoToData(response[apiResponseKey]);
                responseData = this.updateDateFormat(responseData, this.state.dateFormat);
              } else {
                if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                  totalRecordsLength = response.pagination.totalCount;
                }
              }
            }
            await this.setState({
              allUsersData: responseData,
              isLoading: false,
              totalRecordsLength: totalRecordsLength
            })
            return responseData;
          }).catch((err) => {
            return err;
          });
      }
    }
  }
    //get table list data from server with filters if any
  getElasticSeachDataFromServer = async () => {
    let apiUrl = `api/seller/getElasticQueryResults?filter=${JSON.stringify(this.state.filterCriteria)}`;
    await this.setState({
      copyQuery: null,
      isLoading: true
    })
    let body = {
      scoringType: this.state.scoringType,
      elasticIndex: this.state.elasticIndex,
      name: this.state.name,
      state: this.state.state,
      addressLine1: this.state.addressLine1,
      selectedLayer: this.state.selectedLayer
    }
    return await fetchMethodRequest("POST", apiUrl, body)
      .then(async (response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
          let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            totalRecordsLength = response.pagination.totalCount;
          }
          await this.setState({
            allUsersData: responseData,
            allUsersData1: responseData,
            isLoading: false,
            copyQuery: response.copyQuery != null ? JSON.stringify(response.copyQuery) : null,
            totalRecordsLength: totalRecordsLength
          })
        }
      })

  }
  sortData = async (value, responseData) => {
    let responseDataModified = []
    responseDataModified = await responseData.sort(function (a, b) {
      var x = Number(b[value]); var y = Number(a[value]);
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    return responseDataModified
  }
  getSearchDataFromServer = async (filterCriteria, type) => {
    console.log("this.state.gettable data", this.state.tablefieldsToShow1)
    let flag = this.state.database == 'TEST' ? 'elastic' : 'opp'
    let request;
    let ThresholdValues = {
      greenNameOne: this.state.greenNameOne,
      greenNameTwo: this.state.greenNameTwo,
      greenAddOne: this.state.greenAddOne,
      greenAddTwo: this.state.greenAddTwo,
      greenCSZ_One: this.state.greenCSZ_One,
      greenCSZ_Two: this.state.greenCSZ_Two,
      blueNameOne_1: this.state.blueNameOne_1,
      blueNameOne_2: this.state.blueNameOne_2,
      blueAddOne_1: this.state.blueAddOne_1,
      blueAddOne_2: this.state.blueAddOne_2,
      blueCSZ_One_1: this.state.blueCSZ_One_1,
      blueCSZ_One_2: this.state.blueCSZ_One_2,
      yellowNameOne_1: this.state.yellowNameOne_1,
      yellowNameOne_2: this.state.yellowNameOne_2,
      yellowNameTwo_1: this.state.yellowNameTwo_1,
      yellowNameTwo_2: this.state.yellowNameTwo_2,
      yellowAddOne: this.state.yellowAddOne,
      yellowAddTwo: this.state.yellowAddTwo,
      yellowCSZ_One: this.state.yellowCSZ_One,
      yellowCSZ_Two: this.state.yellowCSZ_Two,
      brownNameOne: this.state.brownNameOne,
      brownNameOne_2: this.state.brownNameOne_2,
      brownAddOne: this.state.brownAddOne,
      brownCSZ_One: this.state.brownCSZ_One,
      tealThreshold: this.state.tealThreshold,
      type: this.state.ScoringType
    }
    request = fetch(`${config.elasticApiUrl}/api/bucket/bucketUpdate?data=${JSON.stringify(ThresholdValues)}`, { method: "GET" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
        }
      })

  }

  getAPIUrl() {
    return this.props.apiUrl;
  }
  getFilterUrl(filterCriteria, type) {
    return this.props.apiUrl;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true })
    let filterCriteria = this.state.filterCriteria;
    delete filterCriteria['limit'];
    delete filterCriteria['page'];
    let url = this.getAPIUrl();
    let apiUrl;
    apiUrl = `${this.state.apiUrl}?filter = ${JSON.stringify(filterCriteria)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          await this.setState({ sessionExpiryModal: true })
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          this.setState({
            exportData: exportData,
            isLoading: false
          }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkRef.link.click();
          });
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  }

  //open Form modal
  openFormModal = async (rowData, type) => {
    await this.setState({
      isOpenFormModal: true,
      formType: type ? type : 'add',
      selectedRows: ''
    });
    if (type === 'view') {
      if (this.state.isOpenFormModal && this.formModalRef) {
        await this.formModalRef.getViewData(rowData);
      }
    }
    if (type == 'edit') {
      if (this.state.isOpenFormModal && this.formModalRef) {
        await this.formModalRef.getRowData(rowData, 'edit');
      }
    }
  }

  //close form modal
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }
  submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false,
          actions: '',
          selectedRows: '',
          selectedRowsId: ''
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }
  handleActions = async (apiType) => {
    console.log(apiType)
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    if (this.state.confirmType === 'Delete' || this.state.confirmType === 'Block' || this.state.confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: this.state.selectedRowsId
      }
    }
    this.submitActionsData(method, url, body)
  }
  // delete selected row
  deleteSelectedRow = async () => {
    if (this.state.confirmType === 'Delete') {
      this.handleActions('multiDelete')
    } else if (this.state.confirmType === 'Block') {
      this.handleActions('multiblock?block=true')
    } else if (this.state.confirmType === 'UnBlock') {
      this.handleActions('multiblock?unblock=true')
    } else if (this.state.confirmType === 'ResetPassword') {
      await this.setState({
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows
      })
    }
  }

  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    console.log(selectActions)
    let selectedRows = [...this.state.selectedRows]
    console.log(selectedRows)
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      await this.setState({
        selectedRowsId: selectedRowsId,
        openDeleteModal: true,
        selectActions: selectActions,
      })
    }
    if (rowData) {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        selectActions: selectActions,
      });
    }
  }

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, 'equals');
    this.setState({
      [event.target.name]: event.value
    });
  }


  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field == field) {
        return this.state.tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (this.state.tablefieldsToShow[i].fieldType == field) {
        return this.state.tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  getTableFieldItem1(field) {
    for (let i = 0; i < this.state.tablefieldsToShow1.length; i++) {
      if (this.state.tablefieldsToShow1[i].field == field) {
        return this.state.tablefieldsToShow1[i];
      }
      //condition to match fieldType
      if (this.state.tablefieldsToShow1[i].fieldType == field) {
        return this.state.tablefieldsToShow1[i]['field'];
      }
    }
    return null;
  }

  getColorFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value == name) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }
  getUserData(_id, type) {
    let data = this.state.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }
  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  openViewModal = async (rowData, type) => {
    let rowDataIndex = this.getUserData(rowData['_id'], '_id');
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (this.props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField]
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key]
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (this.state.displayViewOfForm === 'modal') {
        await this.setState({
          // openViewModal: true
          isOpenFormModal: true,
          userStatus: status,
          formType: type,
        });
        // await this.viewModalRef.getRowData(formFields);
        await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (this.state.displayViewOfForm === 'screen') {
        let displayBreadCrumbField = this.getTableFieldItem('Link');
        await this.setState({
          isOpenFormModal: true,
          formType: type,
          displayBreadCrumbValue: rowData[displayBreadCrumbField],
          displayBreadCrumbField: displayBreadCrumbField,
          userStatus: status
        });
        await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      }
    }
    await this.getDataFromServer();

  }

  closeViewModal = async () => {
    this.setState({
      openViewModal: false
    });
  }
  //on changing pagination
  onPageChange = async (event) => {
    // console.log("event?????????????????????????????????????????????", event)
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      if ((this.state.rows !== event.rows) && this.state.originalResponseData.length > 0) {
        let chunkResponseData = await this.chunkArray(this.state.originalResponseData, event.rows);
        this.setState({
          chunkResponseData: chunkResponseData,
          allUsersData1: chunkResponseData.length > 0 ? chunkResponseData[0] : [],
        })
      }
      this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
        filterCriteria: filterCriteria
      })
      if(this.props.type ==='ElasticQuery'){
         this.getElasticSeachDataFromServer()
      }
    }
    if (this.state.originalResponseData.length > 0) {
      this.setState({
        allUsersData1: this.state.chunkResponseData[event.page],
      })
    }
    // this.getDataFromServer(filterCriteria)
  }

  chunkArray = async (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }
    return tempArray;
  }

  onPageChangeAccordion = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    let id = this.state.openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      this.setState({
        [`rows${id}`]: event.rows,
        page: event.page,
        [`first${id}`]: event.first
      })
    }
    // this.getDataFromServer(filterCriteria)
  }
  //sorting fields
  sortChange = async (event) => {
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({
        sortCount: sortCount,
        isLoadingsearch: true
      });
      if (this.state.rows && this.state.originalResponseData.length > 0) {
        let originalResponseData = this.state.originalResponseData;
        originalResponseData = await originalResponseData.sort(function (a, b) {
          var x = b[sortField]; var y = a[sortField];
          if (sortCount == 0) {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          } else {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
          }
        });
        let chunkResponseData = await this.chunkArray(originalResponseData, this.state.rows);

        this.setState({
          chunkResponseData: chunkResponseData,
          allUsersData1: chunkResponseData.length > 0 ? chunkResponseData[0] : [],
        })
      }
      this.setState({
        isLoadingsearch: false
      })
      // this.getDataFromServer(filterCriteria);
    }
  }
  confirmActionType = async (type) => {
    console.log(type)
    if (type === 'Delete') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Delete',
      })
      this.deleteConfirmAction()
    } else if (type === 'Block') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Block',
      })
      this.deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows
      })
      // this.deleteConfirmAction()
    }
  }

  //onActionsChange
  onActionsChange = async (event, type) => {
    console.log(event)
    if (type == 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value,
      })
      console.log(this.state.actionsTypes)
      for (let i in this.state.actionsTypes) {
        if (this.state.actionsTypes[i].value === event.target.value) {
          if (this.state.actionsTypes[i].action) {
            this.state.actionsTypes[i].action(this.state.selectedRows)
          }
        }
      }
      this.confirmActionType(event.target.value)
    }
  }

  onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = this.state.filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }

      await this.setState({
        filterCriteria: filterCriteria
      })
      await this.getDataFromServer(this.state.filterCriteria)
    } else {
      this.getDataFromServer(this.state.filterCriteria)
    }
  }
  // on search get data from server
  onFilterChange = async (event, type) => {
    this.setState({
      isLoading: true,
      selectedRows: ''
    })
    console.log(type)
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value
      })
    }
    let fieldName = '', filterCriteria = this.state.filterCriteria,
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        this.setState({ globalSearch: selectedFilterValue })
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'eq'
      }
      if ((selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filterCriteria.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          console.log(event.target.value.length)
          if (event.target.value.length == 0) {
            console.log('delete')
            delete filterCriteria.globalSearch;
          }
          // this.setState({ globalSearch: selectedFilterValue })
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filterCriteria.criteria.find(x => x.key == fieldName);
            let index = filterCriteria.criteria.indexOf(obj);
            if (index != -1) {
              filterCriteria['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filterCriteria['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filterCriteria['criteria'].length == 0 && selecterFilterType != 'lte') {
              filterCriteria['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filterCriteria.criteria.find(x => x.key == fieldName);
              let index = filterCriteria.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filterCriteria['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filterCriteria['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filterCriteria['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }
          await this.setState({
            filterCriteria: filterCriteria
          });
        }

        await this.getDataFromServer(this.state.filterCriteria)
      } else {
        if (selectedFilterValue == null && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key == fieldName);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filterCriteria.globalSearch = {}
            delete filterCriteria.globalSearch
          }
          await this.setState({
            filterCriteria: filterCriteria
          });
          await this.getDataFromServer(filterCriteria)
        }
      }
      this.setState({
        isLoading: false
      })
    }
  }

  //open Bulk Modal
  bulkUploadMoadal = () => {
    this.setState({ openBulkUploadModal: true })
  }

  //close Bulk Modal
  closeBulkModal = async () => {
    // this.setState({
    //   openBulkUploadModal: false
    // }, () => {
    //   this.bulkUploadMoadalRef.handleClickCount();
    // })
    this.setState({
      openBulkUploadModal: false
    })
  }

  //select multiple rows to delete
  onSelectRowsUpdate = async (event) => {
    await this.setState({ selectedRows: event.value })
    await this.getActions()
  }

  //openConfirmationModal
  openConfirmationModal = async (rowData, status, type) => {
    this.setState({
      isOpenFormModal: false
    }, async () => {
      this.setState({
        isOpenFormModal: false,
        openConfirmationModal: true,
        leaveStatus: status,
        item: rowData,
        confirmText: type
      })
    })
  }

  closeConfirmationModal() {
    this.setState({
      openConfirmationModal: false
    })
  }
  setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = this.state.filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    await this.setState({
      filterCriteria: filterCriteria
    })
  }
  getTooltipFromOtptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }
  //change table body values//
  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getTableFieldItem(column.field);
    if (!tableItem) {
      tableItem = self.getTableFieldItem1(column.field);
    }
    if (tableItem) {
        if (tableItem.fieldType == "Link") {
            return <div className='textElipses'>
              <span
                onClick={() => this.openFormModal(item, "edit")}>
                {item[column.field]}</span>
            </div >
          } else if (tableItem.fieldType == "dropDown") {
            let mcolor = "info"
            return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
          } else if (tableItem.fieldType == "Badge") {
            let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
            return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
          } else if (tableItem.fieldType == "Role") {
            let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
            return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
          } else if (tableItem.fieldType === "icon") {
            let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
            let tooltip = self.getTooltipFromOtptions(tableItem.options, item[column.field]);
            return <FontAwesomeIcon
              style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
              color='white'
              icon={tableItem.iconName}
              data-toggle="tool-tip"
              title={tooltip}
            />
          } else if (tableItem.fieldType === "Array") {
            let val = this.flattenArray(item[column.field]);
            return <span style={tableItem.style} title={val}>
              {val}
            </span>
          } else if (tableItem.fieldType === "Complex") {
            let data = this.ObjectbyString(item, column.field)
            return <span>
              {data}
            </span>
          } else if (tableItem.fieldType == "Actions") {
            return (
              <div className='row'
                style={{ justifyContent: 'center' }}>
                <div>
                  {(this.props.preview) ? <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='#17a2b8'
                    icon='file'
                    data-toggle="tool-tip"
                    title='Preview'
                    style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
                    onClick={() => this.openPreviewModal(item)} /> : ''}
      
                </div>
                {/* } */}
              </div >
            )
          } else if (tableItem.fieldType == "relateAutoComplete") {
            // return tableItem.searchField ? item[column.field][tableItem.searchField] : null;
            return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ? item[column.field][tableItem.searchField] : null;
      
          } else if (tableItem.fieldType == 'Number') {
            return <span>
              {item[column.field] ? item[column.field].toLocaleString() : '0'}
            </span>
          } else if (tableItem.fieldType == 'Seconds') {
            return <span>
              {item[column.field] ? (item[column.field]).toFixed(3) : '0'}
            </span>
          } else {
        // if (item[column.field] === 0) {
        //   return item[column.field];
        // }
        if ((item[column.field]) && typeof item[column.field] !== 'object') {
          return item[column.field];
        }
      }
    }
  }
  clickConfirm() {
    this.closeConfirmationModal();
  }
  ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  rowClassName(item) {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  openPreviewModal = async (item) => {
    this.setState({
      isPreviewModal: true,
      emailTemplateData: item ? item : item
    })
  }
  closePreviewModal = () => {
    this.setState({
      isPreviewModal: false,
      emailTemplateData: ''
    })
  }
  handleDateInterval(startDate, endDate) {
    if (startDate) {
      this.setState({
        startDate: startDate
      })
    }
    if (endDate) {
      this.setState({
        endDate: endDate
      })
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }
  getSettings = async () => {
    // this.props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      if (this.state.viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (this.state.viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile
          }
        )
      });

      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));

      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
    }
  }
  searchInDateRange() {
    if (this.state.startDate && this.state.endDate) {
      let filterCriteria = {};
      let startDate = (moment(this.state.startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(this.state.endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      this.setState({ filterCriteria: filterCriteria });
      this.getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }
  toggle = async (id) => {
    let openId = this.state.openId;
    this.setState({ openId: openId == id ? '' : id });
  }

  print = () => {
    window.print();
  }
  toogleMultiSelect = async () => {
    await this.setState({
      showMultiSelectDropDown: !this.state.showMultiSelectDropDown
    })
  }
  onGlobalSearchChange = async (e) => {
    let suggestions = this.state.suggestions;
    // suggestions[0] = { "label": `Search By First Name ${e.query}` }
    await this.setState({ suggestions: suggestions })

  }
  onSearchChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      searchValue: e.target.value.label ? e.target.value.label : e.target.value,
    })
    // if (e.target.value!=""){
    this.setAutocompleteSuggestions(e.target.value)
    // }
    // let suggestions = this.state.suggestionsBack

    // suggestions = suggestions.filter(function (obj) {
    //   return obj.label.includes(e.target.value);
    // })
    // if (e.target.value == "") {
    //   suggestions = this.state.suggestionsBack;
    // }
    // if (suggestions.length == 0) {
    //   suggestions[0] = { "label": `No Results` }
    // }

  }

  onEmailChange = async (e) => {
    console.log("event.target.value", e.target.value)
    let re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      this.setState({
        enableSubmit: "yes",
        emailValidate: "no",
        RecevingEmail: e.target.value
      })
    } else if (e.target.value == "") {
      this.setState({
        enableSubmit: "no",
        emailValidate: "no",
        RecevingEmail: e.target.value
      })
    } else {
      this.setState({
        enableSubmit: "no",
        emailValidate: "yes",
        RecevingEmail: e.target.value
      })
    }


  }

  onPercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      percent: e.target.value
    })
  }


  onElasticQueryChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      elasticQuery: e.target.value
    })
  }

  onElasticIndexChange = async (e) => {
    console.log("eeeee", e.target.value)
    this.setState({
      ElasticIndex: e.target.value
    })
  }

  onaddPercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      addPercent: e.target.value
    })
  }

  onBatchNameChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      batchName: e.target.value
    })
  }

  onAddressLineChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      addLinePercent: e.target.value
    })
  }
  ongreenNameOneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenNameOne: e.target.value
    })
  }
  ongreenNameTwoChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenNameTwo: e.target.value
    })
  }
  ongreenAddOneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenAddOne: e.target.value
    })
  }
  ongreenAddTwoChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenAddTwo: e.target.value
    })
  }
  ongreenCSZ_OneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenCSZ_One: e.target.value
    })
  }
  ongreenCSZ_TwoChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      greenCSZ_Two: e.target.value
    })
  }
  onblueNameOne_1Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueNameOne_1: e.target.value
    })
  }
  onblueNameOne_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueNameOne_2: e.target.value
    })
  }
  onblueAddOne_1Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueAddOne_1: e.target.value
    })
  }
  onblueAddOne_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueAddOne_2: e.target.value
    })
  }
  onblueCSZ_One_1Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueCSZ_One_1: e.target.value
    })
  }
  onblueCSZ_One_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      blueCSZ_One_2: e.target.value
    })
  }
  onyellowNameOne_1Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowNameOne_1: e.target.value
    })
  }
  onyellowNameOne_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowNameOne_2: e.target.value
    })
  }
  onyellowNameTwo_1Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowNameTwo_1: e.target.value
    })
  }
  onyellowNameTwo_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowNameTwo_2: e.target.value
    })
  }
  onyellowAddOneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowAddOne: e.target.value
    })
  }
  onyellowAddTwoChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowAddTwo: e.target.value
    })
  }
  onyellowCSZ_OneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowCSZ_One: e.target.value
    })
  }
  onyellowCSZ_TwoChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      yellowCSZ_Two: e.target.value
    })
  }
  onbrownNameOneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      brownNameOne: e.target.value
    })
  }
  onbrownNameOne_2Change = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      brownNameOne_2: e.target.value
    })
  }
  onbrownAddOneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      brownAddOne: e.target.value
    })
  }
  onbrownCSZ_OneChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      brownCSZ_One: e.target.value
    })
  }

  ontealThresholdChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      tealThreshold: e.target.value
    })
  }

  onCompanyNameAChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      CompanyNameA: e.target.value
    })
  }

  onCompanyNameBChange = async (e) => {
    this.setState({
      CompanyNameB: e.target.value
    })
  }

  handleCondition = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      condition: e
    })
  }
  onNameOrAddressChange = async (field, e) => {
    console.log("scoringType",e.target.value)
    await this.setState({
      [field]: e.target.value
    })
  }

  clipToCopyBoard(data) {
    navigator.clipboard.writeText(data)
}
  clearSearch = async () => {
    await this.setState({
      scoringType: "Regular Scoring",
      elasticIndex: "ultimate-property-index-test",
      states: null,
      name: null,
      addressLine1: null,
      selectedLayer: null
    })
  }

  handleDatabase = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      database: e
    })
  }

  updateValidateFlagInSettings = async (e) => {
    this.setState({
      isLoadingsearch: true
    });
    let body = { flag: e }
    let request;
    request = fetch(`${config.elasticApiUrl}/api/settings?flag=${e}`, { method: "Post" })
    return request
      .then(res => {
        console.log("res", res)
        let result = res.json()
        return result
      })
      .then((response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            showToasterMessage(response.respMessage, 'success');
            this.setState({
              runPropertyIdsFlag: e,
              isLoadingsearch: false
            })
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
            this.setState({
              isLoadingsearch: false
            })
          }
        }
      })
  }

  handleFocus = (event) => event.target.select();

  getScreenHeader() {
    const { t } = this.props;
    let Elastic_Indexes = [...ElasticIndexes.array];
    Elastic_Indexes.pop();
    return (
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => this.onFilterChange(e)}
      //           placeholder={this.props.globalSearch ? this.props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
            <h4>
              <span className='postionRelative pt-2'>
                <b
                ><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
                    {t(this.props.type == 'Scorings' ? 'Main' : this.props.type)}
                  </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                </b>
              </span>
            </h4>
          </div>
          {/* className='col-sm-12' */}




        </div>
        <div className='row mr-0 ml-0 p-1'>

          {this.props.type == "Buckets" ?
            <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
              <div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ color: "green", textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "green" }}>Green</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score    >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenNameOne'
                      id='greenNameOne'
                      value={this.state['greenNameOne']}
                      onChange={(e) => this.ongreenNameOneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenAddOne'
                      id='greenAddOne'
                      value={this.state['greenAddOne']}
                      onChange={(e) => this.ongreenAddOneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND City-State-Zip Score >='}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenCSZ_One'
                      id='greenCSZ_One'
                      value={this.state['greenCSZ_One']}
                      onChange={(e) => this.ongreenCSZ_OneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                  <div className='col-sm-6' style={{ textAlign: "center", placeSelf: "center" }}>
                    {"OR"}
                  </div>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score    >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenNameTwo'
                      id='greenNameTwo'
                      value={this.state['greenNameTwo']}
                      onChange={(e) => this.ongreenNameTwoChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenAddTwo'
                      id='greenAddTwo'
                      value={this.state['greenAddTwo']}
                      onChange={(e) => this.ongreenAddTwoChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'OR City-State-Zip Score >='}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='greenCSZ_Two'
                      id='greenCSZ_Two'
                      value={this.state['greenCSZ_Two']}
                      onChange={(e) => this.ongreenCSZ_TwoChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                </div>
              </div>
              <div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "blue" }}>Blue</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score    >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <InputText
                      type="search"
                      name='blueNameOne'
                      id='blueNameOne'
                      value={this.state['blueNameOne']}
                      onChange={(e) => this.onblueNameOneChange(e)}
                      placeholder={'Percentage'}
                      size="10" /> */}
                    <InputText
                      type="search"
                      name='blueNameOne_1'
                      id='blueNameOne_1'
                      value={this.state['blueNameOne_1']}
                      onChange={(e) => this.onblueNameOne_1Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='blueNameOne_2'
                      id='blueNameOne_2'
                      value={this.state['blueNameOne_2']}
                      onChange={(e) => this.onblueNameOne_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score  '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='blueAddOne_1'
                      id='blueAddOne_1'
                      value={this.state['blueAddOne_1']}
                      onChange={(e) => this.onblueAddOne_1Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='blueAddOne_2'
                      id='blueAddOne_2'
                      value={this.state['blueAddOne_2']}
                      onChange={(e) => this.onblueAddOne_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'OR City-State-Zip Score'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='blueCSZ_One_1'
                      id='blueCSZ_One_1'
                      value={this.state['blueCSZ_One_1']}
                      onChange={(e) => this.onblueCSZ_One_1Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='blueCSZ_One_2'
                      id='blueCSZ_One_2'
                      value={this.state['blueCSZ_One_2']}
                      onChange={(e) => this.onblueCSZ_One_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                </div>
              </div>
              <div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "yellow" }}>Yellow</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowNameOne_1'
                      id='yellowNameOne_1'
                      value={this.state['yellowNameOne_1']}
                      onChange={(e) => this.onyellowNameOne_1Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowNameOne_2'
                      id='yellowNameOne_2'
                      value={this.state['yellowNameOne_2']}
                      onChange={(e) => this.onyellowNameOne_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowAddOne'
                      id='yellowAddOne'
                      value={this.state['yellowAddOne']}
                      onChange={(e) => this.onyellowAddOneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND City-State-Zip Score >='}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowCSZ_One'
                      id='yellowCSZ_One'
                      value={this.state['yellowCSZ_One']}
                      onChange={(e) => this.onyellowCSZ_OneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                  <div className='col-sm-6' style={{ textAlign: "center", placeSelf: "center" }}>
                    {"OR"}
                  </div>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowNameTwo_1'
                      id='yellowNameTwo_1'
                      value={this.state['yellowNameTwo_1']}
                      onChange={(e) => this.onyellowNameTwo_1Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowNameTwo_2'
                      id='yellowNameTwo_2'
                      value={this.state['yellowNameTwo_2']}
                      onChange={(e) => this.onyellowNameTwo_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowAddTwo'
                      id='yellowAddTwo'
                      value={this.state['yellowAddTwo']}
                      onChange={(e) => this.onyellowAddTwoChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'OR City-State-Zip Score >='}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='yellowCSZ_Two'
                      id='yellowCSZ_Two'
                      value={this.state['yellowCSZ_Two']}
                      onChange={(e) => this.onyellowCSZ_TwoChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                </div>
              </div>
              <div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "brown" }}>Brown</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score      '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='brownNameOne'
                      id='brownNameOne'
                      value={this.state['brownNameOne']}
                      onChange={(e) => this.onbrownNameOneChange(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;{'-'}&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='brownNameOne_2'
                      id='brownNameOne_2'
                      value={this.state['brownNameOne_2']}
                      onChange={(e) => this.onbrownNameOne_2Change(e)}
                      placeholder={'Percentage'}
                      size="2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND ( Address Line1 Score >= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='brownAddOne'
                      id='brownAddOne'
                      value={this.state['brownAddOne']}
                      onChange={(e) => this.onbrownAddOneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'AND City-State-Zip Score >='}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='brownCSZ_One'
                      id='brownCSZ_One'
                      value={this.state['brownCSZ_One']}
                      onChange={(e) => this.onbrownCSZ_OneChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'      )'}
                  </div>
                </div>
              </div>
              {/* Hiding the Teal bucket as per 2569 */}
              {/* <div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "teal" }}>TEAL</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 pt-1'>
                    {'Name Score'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'>= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputText
                      type="search"
                      name='tealThreshold'
                      id='tealThreshold'
                      value={this.state['tealThreshold']}
                      onChange={(e) => this.ontealThresholdChange(e)}
                      placeholder={'Percentage'}
                      size="10" />
                  </div>
                </div>
              </div> */}
              <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <h2 style={{ color: "black" }}>Black</h2>
                </div>
                <div className='col-sm-10' style={{ textAlign: "left", alignSelf: 'center' }}>
                  {"This is the catch-all bucket. i.e. whichever properties does not fit in any of the above criteria should be marked as Black."}
                </div>
              </div>
              <div className='col-sm-12 pt-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                <Button
                  color="primary"
                  size="sm"
                  // disabled={this.state.searchValue ? false : true}
                  className={'p-1 m-2'}
                  style={{ width: "75px" }}
                  onClick={() => this.getSearchDataFromServer(this.state.filterCriteria, 'refresh')}>
                  Update
                </Button>
              </div>
            </div> :
            this.props.type == "BucketScore" ?
              <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
                <div className='row mr-0 ml-0 p-1'>
                  <div className="col-sm-6" style={{ textAlign: "center" }}>
                    <div className='pl-3' style={{ textAlign: "left" }}><h3><b>Bucket Check</b></h3></div>
                    <div className='row mr-0 ml-0 p-1'>
                      <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                        {'Name Score '}
                      </div>
                      <div className='col-sm-6' style={{ textAlign: "left" }}>
                        <InputText
                          type="search"
                          name='percent'
                          id='percent'
                          value={this.state['percent']}
                          onChange={(e) => this.onPercentChange(e)}
                          placeholder={'Percentage'}
                          size="10" />
                      </div>
                    </div>
                    <div className='row mr-0 ml-0 pt-1'>
                      <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                        {'Address Line 1 Score '}
                      </div>
                      <div className='col-sm-6' style={{ textAlign: "left" }}>
                        <InputText
                          type="search"
                          name='addLinePercent'
                          id='addLinePercent'
                          value={this.state['addLinePercent']}
                          onChange={(e) => this.onAddressLineChange(e)}
                          placeholder={'Percentage'}
                          size="10" />
                      </div>
                    </div>
                    <div className='row mr-0 ml-0 pt-1'>
                      <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                        {'City, State & Zip Score '}
                      </div>
                      <div className='row mr-0 ml-0 pt-1'>
                        <div className='col-sm-4' style={{ textAlign: "left" }}>
                          <div className='row'>
                            <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                              <InputText
                                type="search"
                                name='addPercent'
                                id='addPercent'
                                value={this.state['addPercent']}
                                onChange={(e) => this.onaddPercentChange(e)}
                                placeholder={'Percentage'}
                                size="10" />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6' style={{ textAlign: "center", alignSelf: 'center', fontSize: '-webkit-xxx-large' }}>
                    {this.state.BucketFromScore}
                  </div>
                </div>
                <div className='col-sm-12 pt-1' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center", fontSize: 'x-small' }}>
                    {'Note: Teal bucket will not be displayed.'}
                  </div>
                  <Button
                    color="primary"
                    size="sm"
                    // disabled={this.state.searchValue ? false : true}
                    className={'p-1 m-2'}
                    style={{ width: "175px" }}
                    onClick={() => this.getBucketDataFromServer(this.state.filterCriteria, 'refresh')}>
                    Get related Bucket
                  </Button>
                  <Loader loader={this.state.isLoadingsearch} />
                </div>
              </div> :
              this.props.type == "CompareStrings" ?
                <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row mr-0 ml-0 p-1'>
                    <div className="col-sm-6" style={{ textAlign: "center" }}>
                      <div className='pl-3' style={{ textAlign: "left" }}><h3><b>String Comparision</b></h3></div>
                      <div className='row mr-0 ml-0 p-1'>
                        <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                          {'Company Name A '}
                        </div>
                        <div className='col-sm-6' style={{ textAlign: "left" }}>
                          <InputText
                            type="search"
                            name='CompanyNameA'
                            id='CompanyNameA'
                            value={this.state['CompanyNameA']}
                            onChange={(e) => this.onCompanyNameAChange(e)}
                            placeholder={'Company Name A'}
                            size="50" />
                        </div>
                      </div>
                      <div className='row mr-0 ml-0 pt-1'>
                        <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                          {'Company Name B '}
                        </div>
                        <div className='col-sm-6' style={{ textAlign: "left" }}>
                          <InputText
                            type="search"
                            name='CompanyNameB'
                            id='CompanyNameB'
                            value={this.state['CompanyNameB']}
                            onChange={(e) => this.onCompanyNameBChange(e)}
                            placeholder={'CompanyNameB'}
                            size="50" />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-6' >
                      <div className='row mr-0 ml-0 pt-1'>
                        <span className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center', fontSize: '-webkit-xxx-large' }}>
                          {this.state.ScoreResult}
                        </span>
                      </div>
                      <div className='row mr-0 ml-0 pt-1'>
                        <span className='col-sm-12'>{this.state.reason}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 pt-1' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <Button
                      color="primary"
                      size="sm"
                      disabled={this.state.CompanyNameA && this.state.CompanyNameB ? false : true}
                      className={'p-1 m-2'}
                      style={{ width: "175px" }}
                      onClick={() => this.CompareTwoStrings(this.state.filterCriteria, 'refresh')}>
                      Get Score
                    </Button>
                    <Loader loader={this.state.isLoadingsearch} />
                  </div>
                </div> :
                this.props.type == "Setting" ?
                  <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <div className='col-sm-12 pt-1' style={{ textAlign: "center", alignSelf: 'center' }}>
                      <div className='pl-3' style={{ textAlign: "left" }}><h3><b>Current status: {this.state.runPropertyIdsFlag == 'start' ? "Started" : "Stopped"}</b></h3></div>
                      <div className='pl-3' style={{ textAlign: "left", placeSelf: "center" }}>
                        {'Note:Click on the button to start or stop process'}
                      </div>
                      {(this.state.runPropertyIdsFlag == "start") ?
                        <FontAwesomeIcon
                          className='genderIconAlignment'
                          color='red'
                          icon={faStop}
                          data-toggle="tool-tip"
                          title='toggle'
                          style={{ color: 'red', width: '50', height: '50', cursor: 'pointer', marginRight: 9 }}
                          onClick={() => this.updateValidateFlagInSettings("stop")} />
                        :
                        <FontAwesomeIcon
                          className='genderIconAlignment'
                          color='green'
                          icon={faPlay}
                          data-toggle="tool-tip"
                          title='toggle'
                          style={{ color: '#6aba61', width: '50', height: '50', cursor: 'pointer', marginRight: 9 }}
                          onClick={() => this.updateValidateFlagInSettings("start")} />
                      }
                      <Loader loader={this.state.isLoadingsearch} />
                    </div>
                  </div> :
                  this.props.type == "SearchNames" ?
                    <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
                      <div className='row mr-0 ml-0 p-1'>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                          {/* <div className='pl-3' style={{ textAlign: "left" }}><h3><b>Bucket Check</b></h3></div> */}
                          <div className='row mr-0 ml-0 p-1' >
                            <div className='col-sm-3' style={{ textAlign: "left", placeSelf: "center" }}>
                              {'Elastic Query '}
                            </div>
                            <div className='col-sm-9' style={{ textAlign: "left" }}>
                              <textarea
                                type="search"
                                name='elasticQuery'
                                id='elasticQuery'
                                value={this.state['elasticQuery']}
                                onChange={(e) => this.onElasticQueryChange(e)}
                                placeholder={'Insert New Elastic Query'}
                                rows="30"
                                style={{ width: "70%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 pt-1' style={{ textAlign: "center", alignSelf: 'center' }}>
                        <Button
                          color="primary"
                          size="sm"
                          // disabled={this.state.searchValue ? false : true}
                          className={'p-1 m-2'}
                          style={{ width: "175px" }}
                          onClick={() => this.getElasticQueryDataFromServer(this.state.filterCriteria, 'refresh')}>
                          Generate CSV
                        </Button>
                        <CSVLink
                          data={this.state.exportData}
                          filename={`${this.props.type}.csv`}
                          className="hidden text-white"
                          ref={(r) => this.csvLinkRef = r}
                          target="_blank" >
                        </CSVLink>
                        <Loader loader={this.state.isLoadingsearch} />
                      </div>
                    </div>
                    : 
                    <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
                      <div className='row mr-0 ml-0 pt-1'>
                        <div className='col-sm-10'>
                          <div className='col-sm-12 d-flex'>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'Scoring Type'}
                            </div>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "left" }}>
                              <Dropdown
                                name='scoringType'
                                id='scoringType'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                appendTo={document.body}
                                value={this.state['scoringType']}
                                options={this.state.scoringTypeOptions}
                                onChange={(e) => this.onNameOrAddressChange("scoringType", e)}
                              />
                            </div>
                          </div>
                          <div className='col-sm-12 d-flex'>

                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'Elastic Index'}
                            </div>
                            <div className='col-sm-6 mb-2 d-flex' style={{ textAlign: "left" }}>
                              <Dropdown
                                name='elasticIndex'
                                id='elasticIndex'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                appendTo={document.body}
                                value={this.state['elasticIndex']}
                                options={this.state.elasticIndexOptions}
                                onChange={(e) => this.onNameOrAddressChange("elasticIndex", e)}
                              />
                              <Button
                                color="primary"
                                size="sm"
                                // disabled={this.state.searchValue ? false : true}
                                className={'p-1 m-0 ml-5'}
                                style={{ width: "75px" }}
                                onClick={() => this.getElasticSeachDataFromServer()}>
                                GO
                              </Button>
                              <Button
                                color="primary"
                                size="sm"
                                className={'p-1 m-0 ml-5'}
                                style={{ width: "75px" }}
                                onClick={() => this.clearSearch()}>
                                Clear
                              </Button>
                            </div>

                          </div>
                          <div className='col-sm-12 d-flex'>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'Select Layer'}
                            </div>
                            <div className='col-sm-6 mb-2 ' style={{ textAlign: "left" }}>
                              <Dropdown
                                name='selectedLayer'
                                id='selectedLayer'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                appendTo={document.body}
                                value={this.state['selectedLayer']}
                                options={this.state['scoringType'] == "Regular Scoring" ? this.state.regularLayerOptions : this.state.dealSizingLayerOptions}
                                onChange={(e) => this.onNameOrAddressChange("selectedLayer", e)}
                              />
                              <Button
                                color="primary"
                                size="sm"
                                className={'p-1 m-0 ml-5'}
                                style={{ width: "75px" }}
                                disabled={this.state.copyQuery == null ? true :false}
                                onClick={() => this.clipToCopyBoard(this.state.copyQuery)}>
                                Copy
                              </Button>

                            </div>

                          </div>
                      {this.state.selectedLayer != null && (this.state.selectedLayer == "loaderLayer1" || this.state.selectedLayer == "loaderLayer2" || this.state.selectedLayer == "DealSizing") ?
                          <div className='col-sm-12 d-flex'>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'Name'}
                            </div>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "left" }}>
                              <InputText
                                type="search"
                                name='Name'
                                id='Name'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                value={this.state['name']}
                                onChange={(e) => this.onNameOrAddressChange("name", e)}
                                placeholder={'Name'}
                              />
                             
                            </div>
                          </div>:null}

                          {this.state.selectedLayer != null && (this.state.selectedLayer == "loaderLayer3" || this.state.selectedLayer == "loaderLayer2" || this.state.selectedLayer == "loaderLayer4") ?
                          <div className='col-sm-12 d-flex'>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'Address Line1'}
                            </div>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "left" }}>
                              <InputText
                                type="search"
                                name='addressLine1'
                                id='addressLine1'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                value={this.state['addressLine1']}
                                onChange={(e) => this.onNameOrAddressChange("addressLine1", e)}
                                placeholder={'Address Line1'}
                              />
                            </div>
                          </div>: null}

                          {this.state.selectedLayer != null && (this.state.selectedLayer == "loaderLayer3" || this.state.selectedLayer == "loaderLayer2" || this.state.selectedLayer == "loaderLayer4") ?

                          <div className='col-sm-12 d-flex'>
                            <div className='col-sm-6 mb-2' style={{ textAlign: "right", placeSelf: "center" }}>
                              {'State'}
                            </div>
                            <div className='col-sm-6 mb-2 ' style={{ textAlign: "left" }}>
                              <Dropdown
                                name='state'
                                id='state'
                                style={{ minWidth: '50%', lineHeight: 1.1 }}
                                appendTo={document.body}
                                value={this.state['state']}
                                options={this.state['states']}
                                onChange={(e) => this.onNameOrAddressChange("state", e)}
                              />
                            </div>

                          </div>:null}

                        </div>

                      </div>



                    </div>
          }
        </div>
      </div >
    )
  }
  onGlobalSerachFilterChange = async (event) => {
    if (event) {
      await this.setState({
        [event.target.name]: event.target.value
      })
      await this.onFilterChange(event)
    }

  }
  getHeader() {
    const { t } = this.props;
    return (
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => this.onFilterChange(e)}
      //           placeholder={this.props.globalSearch ? this.props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
            <h4 >
              <span className='postionRelative pt-2'>
                <b
                ><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
                    {t(this.props.type)}
                  </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                </b>
              </span>
              <span className='float-right pl-3 '>
                {this.props.globalSearch && <div>
                  <div
                    className="p-inputgroup"
                  >
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-search" ></i>
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={this.state['globalSearch']}
                      onChange={(e) => this.onFilterChange(e)}
                      placeholder={this.props.globalSearch ? (this.props.globalSearch) : 'search'}
                      size="30" />

                    {/* <AutoComplete
                      // onSelect={(e) => console.log(e)}
                      multiple
                      value={this.state.selectedAutoCompleteValue}
                      suggestions={this.state.suggestions}
                      completeMethod={(e) => this.onGlobalSearchChange(e)}
                      field={'label'}
                      placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                      onChange={(e) => this.setState({ selectedAutoCompleteValue: e.value })}
                    // onChange={(e) => console.log(e)}
                    /><span className='searchIconGlobal'>
                      <i className="pi pi-search" ></i>
                    </span> */}
                    {/* <input
                        type="search"
                        name='globalSearch'
                        id='globalSearch'
                        value={this.state['globalSearch']}
                        onChange={(e) => this.onFilterChange(e)}
                        placeholder={this.props.globalSearch ? (this.props.globalSearch) : 'search'}
                      /> */}

                  </div>
                </div>}
              </span>
            </h4>
          </div>
          <div className='col-12  pb-1'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {this.getPaginator()}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {this.props.filterRequired ?
                  <span className='mr-3' ref={this.closeMultiSelectDropdown}>
                    <span className='multiSelectDropDownCard '>
                      <span onClick={this.toogleMultiSelect}>
                        {t('Filters')}
                      </span>
                      <span onClick={this.toogleMultiSelect}>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='ml-1'
                          color='grey'
                        />
                      </span>
                      {
                        this.state.showMultiSelectDropDown ?
                          <Card className=' itemsWarperCard' >
                            {
                              this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0 ? this.state.tablefieldsToShow.map((item, index) => {
                                return (
                                  item.displayInFilter && item.show && < div >
                                    <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                    <ul className='pl-0'>
                                      {
                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                          return (
                                            <div className='multiSelectWrapperItems' onClick={() => this.changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                              <span className='chekcIconAdjust'>
                                                <Checkbox
                                                  // onChange={e => setChecked(e.checked)}
                                                  checked={optionsItem.checkIcon}>
                                                </Checkbox>
                                                {/* <FontAwesomeIcon
                                           icon='check'
                                           color={optionsItem.checkIcon ? 'grey' : 'white'}
                                         /> */}
                                              </span>
                                              <span className='chekcIconAdjustLabel'>
                                                {t(optionsItem.label)}
                                              </span>
                                            </div>
                                          )
                                        }) : null
                                      }
                                    </ul>
                                    <hr className='my-0'></hr>
                                  </div>
                                )
                              }) : null
                            }
                          </Card>
                          : null}
                    </span>

                  </span>
                  : null}


                {/* {!this.state.isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={this.state['multiActions'] ? this.state['multiActions'] : null}
                  options={this.props.actionsTypes}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={this.state.actions}
                  disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                  options={this.state.actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
                />

                {this.props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => this.print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => this.print()}
                    />
                  </Button>
                  : null}
                {this.props.exportRequired && <Button color="primary"
                  className='p-1 ml-auto mb-0 mt-1'
                  size={'sm'} onClick={this.getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>}
                {this.props.exportRequired && <CSVLink
                  data={this.state.exportData}
                  filename={`${this.props.type}.csv`}
                  className="hidden text-white"
                  ref={(r) => this.csvLinkRef = r}
                  target="_blank" >
                </CSVLink>}
                {this.props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => this.bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => this.bulkUploadMoadal()} />
                  </Button>
                  : null}

                {this.props.gridRequried && <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={this.state.viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => this.changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={this.state.viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => this.changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup>}
                {this.props.settingsRequired && <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>}

                {/* Add button */}
                {this.props.addRequired ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.openFormModal('add')}>
                    <FontAwesomeIcon
                      icon='plus'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Add")}
                      onClick={() => this.openFormModal('add')}
                    />
                  </Button>
                  : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={this.props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>

              </span>
            </span>
          </div>
        </div>

      </div >
    )
  }
  getCardHeader() {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{this.props.type}{this.props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={this.getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {this.props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={this.bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={this.bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {this.props.settingsRequired ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => this.openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => this.openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {this.props.addRequired ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto"
                onClick={() => this.openFormModal('add')}>
                <FontAwesomeIcon
                  icon='plus'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Add"
                  onClick={() => this.openFormModal('add')}
                />
              </Button>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={this.props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }
  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: ''
    })
  }

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0) {
      return this.state.tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={self.e}
            deleteRequired={self.d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 12,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            filterElement={item.filterElement ?
              <div style={{ height: 26 }}>
                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.1 }}
                  appendTo={document.body}
                  name={item.field}
                  value={this.state[item.field]}
                  options={item.filterElement}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
              </div>
              : null
            }
            selectionMode={item.selectionMode}
          />
        )

        return column;
      })
    }

  }

  getColumns1(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow1 && this.state.tablefieldsToShow1.length > 0) {
      return this.state.tablefieldsToShow1.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={self.e}
            deleteRequired={self.d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 12,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            filterElement={item.filterElement ?
              <div style={{ height: 26 }}>
                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.1 }}
                  appendTo={document.body}
                  name={item.field}
                  value={this.state[item.field]}
                  options={item.filterElement}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
              </div>
              : null
            }
            selectionMode={item.selectionMode}
          />
        )

        return column;
      })
    }

  }

  getDataTable(flag) {
    let self = this;
    self.editRequired = this.props.editRequried;
    self.deleteRequired = this.props.deleteRequried;

    if (this.props.type == "ElasticQuery" &&  this.state.allUsersData.length) {    
      return (
        <div>
                    {!this.state.isOpenFormModal ? this.getPaginator() : null}

          <Loader loader={this.state.isLoading} />
          <DataTable
            rowClassName={this.rowClassName}
            ref={(el) => this.dt = el}
            value={flag == "one" ? this.state.allUsersData : this.state.allUsersData1}
            // header={this.getHeader()}
            totalRecords={this.state.totalRecordsLength}
            paginator={false}
            lazy={true}
            resizableColumns={true}
            columnResizeMode="expand"
            onSort={this.sortChange}
            globalFilter={this.state.globalFilter}
            onFilter={this.onFilterChange}
            scrollable={true}
            // selection={false}
            onSelectionChange={e => this.onSelectRowsUpdate(e)}
            scrollHeight='1000px'
            // style={{ marginTop: 0 }}
            emptyMessage={configMessages.noRecords}
            sortMode="single"
            // sortField="fname"
            // sortOrder={-1}
            selection={this.state.selectedRows}
            // selectionMode={'multiple'}
            metaKeySelection={false}
            style={this.state.allUsersData && this.state.allUsersData.length == 0 ?
              { textAlign: 'center' }
              : null}
          >
            {flag == "one" ? self.getColumns(self.editRequired, self.deleteRequired) : self.getColumns1(self.editRequired, self.deleteRequired)}

          </DataTable>
        </div>
      )
    }
    else {
      return (<></>);
    }
  }
  getTabInfo() {
    //console.log('from datatable  getTabinfo');
    return null;
  }

  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    )
  }

  //getGridView
  getGridView() {
    console.log(this.state.allUsersData)
    console.log(this.state.tablefieldsToShow)
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          this.state.allUsersData && this.state.allUsersData.length > 0 ?
            this.state.allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      {
                        this.state.tablefieldsToShow && this.state.tablefieldsToShow.length && this.state.tablefieldsToShow.length > 0 ?
                          this.state.tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {/* {this.changeFieldValues(item, element)} */}
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => this.openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <Badge color={item.status == 'Active' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'}>
                                            {item[elememt.field]}
                                          </Badge>
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            <Badge pill
                                              color='success'
                                            >
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?
                                              <div>
                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {this.flattenArray(item[elememt.field])}
                                                </span>
                                                : <div style={elememt.style}>
                                                  <span
                                                  >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }

  getTotalCard() {
    //   console.log(this.state.displayViewOfForm)
    if (this.state.viewType === 'list') {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'  || this.props.type ==='ElasticQuery'  ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal'? this.getHeader() : null}
            {this.getTabInfo()}
            <div className='row mr-0 ml-0'>
              {/* <div className='col-sm-6 px-4 pb-4'>
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? 'OPRA Results' : ''}
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
                  ? this.getDataTable('one') : this.state.displayViewOfForm === 'modal' ? this.getDataTable('one') : null}
              </div> */}
              <div className='col-sm-12 px-4 pb-4'>
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ?
                  <div className='row mr-0 ml-0'>
                    {/* <div className='col-sm-4'>
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => this.openFormModal('add')}>
                        {'Add Estate'}
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={"Add"}
                          onClick={() => this.openFormModal('add')}
                        />
                      </Button>
                    </div>
                    <div className='col-sm-4'>
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => this.openFormModal('add')}>
                        {'Add Property'}
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={"Add"}
                          onClick={() => this.openFormModal('add')}
                        />
                      </Button>
                    </div> */}
                  </div> : ''}
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
                  ? this.getDataTable('two') : this.state.displayViewOfForm === 'modal' ? this.getDataTable('two') : null}
              </div>
            </div>
            {this.state.isOpenFormModal ? this.getFormModal() : null}
          </CardBody>
        </Card>
      )
    } else if (this.state.viewType === 'grid') {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
              {this.getTabInfo()}
              {this.state.isOpenFormModal ? this.getFormModal() : null}
            </CardBody>
          </Card>
          {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getGridView() : this.state.displayViewOfForm === 'modal' ? this.getGridView() : null}
        </div>
      )
    }
  }

  getMobileCard() {
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        {this.state.isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{this.props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {this.state.isOpenFormModal ? this.getMobileForm() : this.getGridView()}
        {!this.state.isOpenFormModal ? <PaginatorComponent
          totalRecords={this.state.totalRecordsLength}
          first={this.state.first}
          rows={this.state.rows}
          onPageChange={this.onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  getMobileForm() {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {this.getScreenHeader()} */}
              {this.getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  getFormFields = () => {
    this.formModalRef.getFormFields()
  }
  getFormModal() {
    return (
      <FormModal
        onRef={(ref) => this.formModalRef = ref}
        openFormModal={this.state.isOpenFormModal}
        allUsersData={this.state.allUsersData}
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        closeFormModal={this.closeFormModal}
        closeRejectModal={this.closeRejectModal}
        type={this.props.type}
        tablefieldsToShow={this.state.tablefieldsToShow}
        originalTableFields={this.state.originalTableFields}
        formType={this.state.formType}
        formFields={this.props.formFields}
        getDataFromServer={this.getDataFromServer}
        editRequired={this.props.editRequired}
        getEditInfoKeys={this.props.getEditInfoKeys}
        filterExtension={this.props.filterExtension}
        idNotRequired={this.props.idNotRequired}
        apiUrl={this.props.formUrl ? this.props.formUrl : this.state.apiUrl}
        role={this.state.loginRole}
        tabType={this.state.tabType}
        getDoctorPostingFields={this.props.getDoctorPostingFields}
        categoryNames={this.props.categoryNames}
        companiesList={this.props.companiesList}
        getSettings={this.getSettings}
        filterCriteria={this.state.filterCriteria}
        menuList={this.props.menuList}
        routeTo={this.props.routeTo}
        displayViewOfForm={this.state.displayViewOfForm}
        displayBreadCrumbValue={this.state.displayBreadCrumbValue}
        displayBreadCrumbField={this.state.displayBreadCrumbField}
        userStatus={this.state.userStatus}
        actionsTypes={this.state.actionsTypes}
        entityType={this.props.entityType}
        setFilterCriteriaForActivities={this.setFilterCriteriaForActivities}
      />
    )
  }

  getViewModal() {
    return (
      <ViewModal
        type={this.props.type}
        openViewModal={this.state.openViewModal}
        displayViewOfForm={this.state.displayViewOfForm}
        rowData={this.state.rowData}
        formFields={this.props.formFields}
        onRef={(ref) => (this.viewModalRef = ref)}
        closeViewModal={this.closeViewModal}
        locationId={this.state.locationId}
        drawers={this.state.drawers}
      />
    )
  }

  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        viewType={this.state.viewType}
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
      />
    )
  }
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={this.state.confirmModalText}
      />
    )
  }
  getPreviewModal() {
    return (
      <PreviewModal
        isPreviewModal={this.state.isPreviewModal}
        closePreviewModal={this.closePreviewModal}
        emailTemplateData={this.state.emailTemplateData}
        type={this.props.type}
      />
    )
  }
  getBulkUploadModal() {
    return (
      <BulkUploadModal
        onRef={(ref) => this.bulkUploadMoadalRef = ref}
        bulkApi={this.props.bulkApi}
        type={this.props.type}
        sample={this.props.sample}
        sampleFilePath={this.props.sampleFilePath}
        openBulkUploadModal={this.state.openBulkUploadModal}
        closeBulkModal={this.closeBulkModal}
        reload={this.getDataFromServer}
      />
    )
  }

  getConfirmatioinModal() {
    return (
      <ConfirmationModal
        formType={this.state.formType}
        onRef={(ref) => (this.confirmRef = ref)}
        openConfirmationModal={this.state.openConfirmationModal}
        closeConfirmationModal={this.closeConfirmationModal}
        confirm={this.clickConfirm}
        text={this.state.confirmText}
      />
    )
  }

  getSessionExpiryModal() {
    return (
      <SessionExpiryModal
        SOpen={this.state.sessionExpiryModal}
      />
    )
  }

  render() {
    return (
      <div >
        {this.state.width <= 576 && this.getMobileCard()}
        {this.state.width >= 577 && this.getTotalCard()}
        {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
        {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
        {this.state.openViewModal ? this.getViewModal() : null}
        {this.props.sample ? this.getBulkUploadModal() : null}
        {this.state.openConfirmationModal ? this.getConfirmatioinModal() : null}
        {this.state.sessionExpiryModal ? this.getSessionExpiryModal() : null}
        {this.state.isPreviewModal ? this.getPreviewModal() : null}
      </div >
    );
  }
}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
