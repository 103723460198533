import React from 'react';
import DataTables from '../../CommonDataTable/DataTableBucket';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class ElasticQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {

    return [     
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": "Link",
        "field": "_id",
        "mobile": true,
        "header": "Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "statePropertyId",
        "mobile": true,
        "header": "State Property Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "sourceStateAbbreviation",
        "mobile": true,
        "header": "Source State",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyStatus",
        "mobile": true,
        "header": "Property Status",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyValueMax",
        "mobile": true,
        "header": "Prop. Max Value",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyValueMin",
        "mobile": true,
        "header": "Prop. Min Value",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 250,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "propertyType",
        "mobile": true,
        "header": "Property Type",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "lastActivityDateUTC",
        "mobile": true,
        "header": "Last Activity Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "datePropertyCreatedUTC",
        "mobile": true,
        "header": "Property Created",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "datePropertyUpdatedUTC",
        "mobile": true,
        "header": "Property Updated",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },

    ]
  };

  getTableFields1 = () => {

    return [
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": "Link",
        "field": "_id",
        "mobile": true,
        "header": "Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "statePropertyId",
        "mobile": true,
        "header": "State Property Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "sourceStateAbbreviation",
        "mobile": true,
        "header": "Source State",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyStatus",
        "mobile": true,
        "header": "Property Status",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyValueMax",
        "mobile": true,
        "header": "Prop. Max Value",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyValueMin",
        "mobile": true,
        "header": "Prop. Min Value",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 250,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "propertyType",
        "mobile": true,
        "header": "Property Type",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "lastActivityDateUTC",
        "mobile": true,
        "header": "Last Activity Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "datePropertyCreatedUTC",
        "mobile": true,
        "header": "Property Created",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "datePropertyUpdatedUTC",
        "mobile": true,
        "header": "Property Updated",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },

    ]
  };

  getFormFields = () => {
    return (     [
      {
          'show': true,
          "value": "",
          "type": "text",
          "name": "_id",
          "label": "_id",
          "id": "_id",
          "placeholder": "_id",
          "required": true,
          disable: true
      },
      {
          'show': true,
          "value": "",
          "type": "textarea",
          "name": "Query",
          "label": "Query",
          "id": "Query",
          "placeholder": "Query",
          "required": true,
          "fieldType": 'Object',
          style: { background: 'white', height: "500px" }
      }
  ]);
  }
  getFormFields1 = () => {
    return (     [
      {
          'show': true,
          "value": "",
          "type": "text",
          "name": "_id",
          "label": "_id",
          "id": "_id",
          "placeholder": "_id",
          "required": true,
          disable: true
      },
      {
          'show': true,
          "value": "",
          "type": "textarea",
          "name": "Query",
          "label": "Query",
          "id": "Query",
          "placeholder": "Query",
          "required": true,
          "fieldType": 'Object',
          style: { background: 'white', height: "500px" }
      }
  ]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propretyName", "type": "text", "placeholder": "propretyName", "label": "Matching Property Name", "id": "propretyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propretyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "nameScore", "type": "text", "placeholder": "nameScore", "label": "Name Score", "id": "nameScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "nameScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }
  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();


  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          printRequried={false}
          actionsTypes={[]}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}
          globalSearch={'Name String'}
          type='ElasticQuery'
          routeTo={""}
          displayViewOfForm='modal'
          apiResponseKey={"details"}
          apiUrl={""}
        />

      </span>
    );
  }
}