import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import "../../../scss/scoringDetails.scss"

import { reduxForm } from 'redux-form';
import Loader from '../../App/Loader';
class NAUPALogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.getDateFromServer()
        this.intervalId = setInterval(() => {
            this.getDateFromServer();
        }, 10000);
        let data = this.props.emailTemplateData;
        if (document.getElementById('templateText')) {
            document.getElementById('templateText').innerHTML = data['templateText']
        }
    }

    componentWillUnmount() {
        // Cleanup interval
        clearInterval(this.intervalId);
    }

    getDateFromServer = async () => {
        if (!this.state.scoringRecord || (this.state.scoringRecord && this.state.scoringRecord.status !== "Completed")) {
            fetchMethodRequest('GET', `api/bucketLogs/getLogs/${this.props.ScoringDetailsRecord._id}`)
                .then(async (response) => {
                    if (response) {
                        // console.log("response", response)
                        this.setState({ scoringRecord: response.log_ns_bucket_manual_update })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }


    render() {
        return (
            <div style={{ display: 'flex' }}>
                <Loader loader={this.state.isLoading} />
                <Modal isOpen={this.props.isNAUPALogModal}
                    className={` modal-dialog--primary modal-dialog--header `}
                    style={{ maxWidth: "1500px" }}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Log Details
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={this.props.closeNAUPALogModal} />
                    </ModalHeader>
                    <ModalBody >
                        <div className="scoring-table-container">
                            <table className="scoring-table">
                                <thead>
                                    <tr>
                                        <th>Deal</th>
                                        <th>Records Completed</th>
                                        <th>Last Update DateTime CT</th>
                                    </tr>
                                </thead>
                                {this.state.scoringRecord && this.state.scoringRecord.info && this.state.scoringRecord.info.length ?
                                    <tbody>
                                        {this.state.scoringRecord.info.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.dealId}</td>
                                                <td>{`${item.updatedProperties} / ${item.totalProperties} Completed`}</td>
                                                <td>{item.date}</td>
                                            </tr>
                                        ))}
                                    </tbody> : ""}
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
NAUPALogModal = reduxForm({
    form: "NAUPALogModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(NAUPALogModal);

export default withTranslation('common')(NAUPALogModal);